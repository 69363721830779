/**
 * @generated SignedSource<<31d5e68fa3f8b27ce5fc72ac0e78438f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TradeStation_data$data = {
  readonly bestAsk: {
    readonly " $fragmentSpreads": FragmentRefs<"ListingFeesSupportsCreator_orders" | "TradeStation_bestAsk">;
  } | null;
  readonly bestBid: {
    readonly " $fragmentSpreads": FragmentRefs<"TradeStation_bestBid">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"OfferModal_tradeSummary">;
  readonly " $fragmentType": "TradeStation_data";
};
export type TradeStation_data$key = {
  readonly " $data"?: TradeStation_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"TradeStation_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TradeStation_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderV2Type",
      "kind": "LinkedField",
      "name": "bestAsk",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TradeStation_bestAsk"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ListingFeesSupportsCreator_orders"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderV2Type",
      "kind": "LinkedField",
      "name": "bestBid",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TradeStation_bestBid"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OfferModal_tradeSummary"
    }
  ],
  "type": "TradeSummaryType",
  "abstractKey": null
};

(node as any).hash = "2a2457324f5fbe0c73e979da3c255b4a";

export default node;
