/**
 * @generated SignedSource<<8f685bef93000c0bbcae0d4ed7f2c24c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
export type OrderV2OrderType = "BASIC" | "CRITERIA" | "DUTCH" | "ENGLISH" | "%future added value";
export type VerificationStatus = "MINTABLE" | "SAFELISTED" | "UNAPPROVED" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TradeStation_bestAsk$data = {
  readonly closedAt: string | null;
  readonly englishAuctionReservePriceType: {
    readonly unit: string;
  } | null;
  readonly item: {
    readonly __typename: string;
    readonly assetContract?: {
      readonly address: string;
    };
    readonly chain: {
      readonly identifier: ChainIdentifier;
      readonly isTradingEnabled: boolean;
    };
    readonly collection?: {
      readonly slug: string;
    };
    readonly isCurrentlyFungible?: boolean;
    readonly relayId: string;
    readonly tokenId?: string;
    readonly verificationStatus: VerificationStatus;
    readonly " $fragmentSpreads": FragmentRefs<"itemEvents_dataV2">;
  };
  readonly maker: {
    readonly address: string;
    readonly " $fragmentSpreads": FragmentRefs<"wallet_accountKey">;
  };
  readonly openedAt: string;
  readonly orderType: OrderV2OrderType;
  readonly payment: {
    readonly asset: {
      readonly relayId: string;
    };
    readonly relayId: string;
    readonly symbol: string;
    readonly " $fragmentSpreads": FragmentRefs<"TokenPricePayment">;
  };
  readonly perUnitPriceType: {
    readonly usd: string;
  };
  readonly priceType: {
    readonly unit: string;
    readonly usd: string;
  };
  readonly relayId: string;
  readonly remainingQuantityType: string;
  readonly taker: {
    readonly " $fragmentSpreads": FragmentRefs<"wallet_accountKey">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"AddToCartAndQuickBuyButton_order" | "OrderPrice" | "OrderPriceChanged_bestAsk" | "OrderUsdPrice" | "QuickBuyButton_order">;
  readonly " $fragmentType": "TradeStation_bestAsk";
};
export type TradeStation_bestAsk$key = {
  readonly " $data"?: TradeStation_bestAsk$data;
  readonly " $fragmentSpreads": FragmentRefs<"TradeStation_bestAsk">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "kind": "InlineDataFragmentSpread",
  "name": "wallet_accountKey",
  "selections": (v3/*: any*/),
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "identifier",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tokenId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "AssetContractType",
  "kind": "LinkedField",
  "name": "assetContract",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "usd",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TradeStation_bestAsk",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "openedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orderType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "englishAuctionReservePriceType",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "maker",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "verificationStatus",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ChainType",
          "kind": "LinkedField",
          "name": "chain",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isTradingEnabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isCurrentlyFungible",
              "storageKey": null
            },
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "CollectionType",
              "kind": "LinkedField",
              "name": "collection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "AssetType",
          "abstractKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "itemEvents_dataV2",
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ChainType",
              "kind": "LinkedField",
              "name": "chain",
              "plural": false,
              "selections": [
                (v5/*: any*/)
              ],
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v6/*: any*/),
                (v7/*: any*/)
              ],
              "type": "AssetType",
              "abstractKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "priceType",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v8/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainingQuantityType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "perUnitPriceType",
      "plural": false,
      "selections": [
        (v8/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentAssetType",
      "kind": "LinkedField",
      "name": "payment",
      "plural": false,
      "selections": [
        (v9/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetType",
          "kind": "LinkedField",
          "name": "asset",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "TokenPricePayment",
          "selections": [
            (v9/*: any*/)
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "taker",
      "plural": false,
      "selections": [
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderPrice"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderUsdPrice"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddToCartAndQuickBuyButton_order"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuickBuyButton_order"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderPriceChanged_bestAsk"
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};
})();

(node as any).hash = "a06f003477420754f6febb1223accbfb";

export default node;
