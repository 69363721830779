import React from "react"
import { Text } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { Tooltip } from "@/design-system/Tooltip"
import { useLocalForageState } from "@/hooks/useLocalForage"
import { useTranslate } from "@/hooks/useTranslate"
import { OrderPriceChanged_bestAsk$key } from "@/lib/graphql/__generated__/OrderPriceChanged_bestAsk.graphql"
import { bn, display } from "@/lib/helpers/numberUtils"
import { LAST_CLICKED_BEST_ASK_DATA_KEY } from "../assets/localstorage"

type Props = {
  bestAsk: OrderPriceChanged_bestAsk$key | null
  className?: string
}

export const OrderPriceChanged = ({
  className,
  bestAsk: bestAskKey,
}: Props) => {
  const t = useTranslate("common")
  const bestAsk = useFragment(
    graphql`
      fragment OrderPriceChanged_bestAsk on OrderV2Type {
        item {
          relayId
        }
        perUnitPriceType {
          usd
        }
      }
    `,
    bestAskKey,
  )

  const [lastClickedBestAskData] = useLocalForageState(
    LAST_CLICKED_BEST_ASK_DATA_KEY,
    "",
  )

  const [lastClickedItemId, lastClickedBestAskQuantityInUsd] =
    lastClickedBestAskData.split("-")

  if (
    lastClickedItemId === bestAsk?.item.relayId &&
    bn(bestAsk.perUnitPriceType.usd).gt(lastClickedBestAskQuantityInUsd)
  ) {
    const priceChange = bn(bestAsk.perUnitPriceType.usd)
      .minus(lastClickedBestAskQuantityInUsd)
      .div(lastClickedBestAskQuantityInUsd)
      .multipliedBy(100)

    // Only show if price change is greater than 500%
    if (priceChange.lt(500)) {
      return null
    }

    return (
      <Tooltip
        content={t(
          "orderPriceChangedTooltip",
          "This item's price changed recently. Make sure you double check that this is the price you want to pay for this item",
        )}
      >
        <Text className={className} color="red-2">
          {t("orderPriceChanged", "Price changed")}:{" +"}
          {display(
            bn(bestAsk.perUnitPriceType.usd)
              .minus(lastClickedBestAskQuantityInUsd)
              .div(lastClickedBestAskQuantityInUsd)
              .multipliedBy(100),
          )}
          %
        </Text>
      </Tooltip>
    )
  }

  return null
}
