/* eslint-disable tailwindcss/no-custom-classname */
import React, { Suspense, useMemo } from "react"
import {
  Icon,
  Text,
  InlineFlex,
  Spinner,
  VerticalAligned,
  SpaceBetween,
  Flex,
} from "@opensea/ui-kit"
import { differenceInDays, differenceInHours, format, isAfter } from "date-fns"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { OrderPrice } from "@/components/assets/price/OrderPrice.react"
import { OrderUsdPrice } from "@/components/assets/price/OrderUsdPrice.react"
import { Expiration } from "@/components/common/Expiration.react"
import { Timer } from "@/components/common/Timer.react"
import { IS_SERVER } from "@/constants/environment"
import {
  useActiveAccount,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { Form } from "@/design-system/Form"
import { Tooltip } from "@/design-system/Tooltip"
import { ListingFeesSupportsCreator } from "@/features/creator-fees/components/ListingFeesSupportsCreator"
import { AssetOfferModal } from "@/features/orders/components/OfferModal/AssetOfferModal.react"
import { COMPROMISED_ACCOUNT_ACTION_DISABLED } from "@/features/settings/constants/messages"
import { useIsSeaportEnabled } from "@/hooks/useIsSeaportEnabled"
import { useScheduledOrderText } from "@/hooks/useScheduledOrderText"
import { useTranslate } from "@/hooks/useTranslate"
import { trackClickMakeOffer } from "@/lib/analytics/events/checkoutEvents"
import {
  trackClosePurchaseFlowMultiModal,
  trackReturnToPreviousStepPurchaseFlowMultiModal,
} from "@/lib/analytics/events/itemEvents"
import {
  ItemAnalyticsContext,
  ItemAnalyticsTracker,
} from "@/lib/analytics/ItemAnalyticsContext"
import { SourceTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/SourceTrackingContext.react"
import { TradeStation_acceptHighestOffer$key } from "@/lib/graphql/__generated__/TradeStation_acceptHighestOffer.graphql"
import { TradeStation_archetype$key } from "@/lib/graphql/__generated__/TradeStation_archetype.graphql"
import { TradeStation_bestAsk$key } from "@/lib/graphql/__generated__/TradeStation_bestAsk.graphql"
import { TradeStation_bestBid$key } from "@/lib/graphql/__generated__/TradeStation_bestBid.graphql"
import { TradeStation_data$key } from "@/lib/graphql/__generated__/TradeStation_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import { bn, display } from "@/lib/helpers/numberUtils"
import { selectClassNames } from "@/lib/helpers/styling"
import { Maybe } from "@/lib/helpers/type"
import { NetworkUnsupportedGate } from "../modals/NetworkUnsupportedGate"
import { AcceptHighestOfferButton } from "./AcceptHighestOfferButton"
import { AddToCartAndQuickBuyButton } from "./AddToCartAndQuickBuyButton/AddToCartAndQuickBuyButton.react"
import { OrderPriceChanged } from "./OrderPriceChanged"
import { QuickBuyButton } from "./QuickBuyButton"
import { QuickBuyModalContent } from "./QuickBuyModalContent"
import { TradeMultiModal } from "./TradeMultiModal.react"

const EXPIRED_THRESHOLD_IN_HOURS = 0

const SERIALIZED_DATE_FORMAT = "MMMM d, yyyy HH:mm:ss"

type Props = {
  archetypeData: TradeStation_archetype$key | null
  data: TradeStation_data$key
  acceptHighestOffer: TradeStation_acceptHighestOffer$key
  onOrdersChanged: () => unknown
}

type MakeOfferButtonProps = {
  onClick: () => unknown
  assetRelayId: string | undefined
  isDisabled?: boolean
  isShort?: boolean
}

/**
 * Should only be used for non-fungibles.
 * For semi-fungibles (ERC1155s) SemiFungibleTradeStation should be used.
 */
export const TradeStation = ({
  archetypeData: archetypeDataKey,
  data: dataKey,
  acceptHighestOffer: acceptHighestOfferDataKey,
  onOrdersChanged,
}: Props) => {
  const t = useTranslate("components")
  const { isActiveAccount } = useWallet()
  const activeAccount = useActiveAccount()

  const data = useFragment(
    graphql`
      fragment TradeStation_data on TradeSummaryType {
        bestAsk {
          ...TradeStation_bestAsk
          ...ListingFeesSupportsCreator_orders
        }
        bestBid {
          ...TradeStation_bestBid
        }
        ...OfferModal_tradeSummary
      }
    `,
    dataKey,
  )

  const bestBid = useFragment<TradeStation_bestBid$key>(
    graphql`
      fragment TradeStation_bestBid on OrderV2Type {
        ...OrderPrice
        ...OrderUsdPrice
        payment {
          relayId
        }
        priceType {
          unit
        }
        perUnitPriceType {
          usd
        }
      }
    `,
    data.bestBid,
  )

  const bestAsk = useFragment<TradeStation_bestAsk$key>(
    graphql`
      fragment TradeStation_bestAsk on OrderV2Type {
        closedAt
        openedAt
        orderType
        englishAuctionReservePriceType {
          unit
        }
        relayId
        maker {
          address
          ...wallet_accountKey
        }
        item {
          __typename
          verificationStatus
          relayId

          chain {
            identifier
            isTradingEnabled
          }
          ... on AssetType {
            tokenId
            isCurrentlyFungible
            assetContract {
              address
            }
            collection {
              slug
            }
          }
          ...itemEvents_dataV2
        }
        priceType {
          unit
          usd
        }
        remainingQuantityType
        perUnitPriceType {
          usd
        }
        payment {
          symbol
          relayId
          asset {
            relayId
          }
          ...TokenPricePayment
        }
        taker {
          ...wallet_accountKey
        }
        ...OrderPrice
        ...OrderUsdPrice
        ...AddToCartAndQuickBuyButton_order
        ...QuickBuyButton_order
        ...OrderPriceChanged_bestAsk
      }
    `,
    data.bestAsk,
  )

  const acceptHighestOffer = useFragment(
    graphql`
      fragment TradeStation_acceptHighestOffer on TradeSummaryType {
        bestBid {
          relayId
        }
        ...AcceptHighestOfferButton_tradeSummary
      }
    `,
    acceptHighestOfferDataKey,
  )

  const archetypeData = useFragment(
    graphql`
      fragment TradeStation_archetype on AssetType {
        verificationStatus
        chain {
          identifier
          isTradingEnabled
        }
        largestOwner {
          owner {
            ...wallet_accountKey
          }
        }
        isCurrentlyFungible
        isListable
        isBiddingEnabled {
          value
          reason
        }
        relayId
        acceptOfferDisabled {
          __typename
        }
        isFastPollingEnabled
        ...AcceptHighestOfferButton_asset
        ...useFulfillSemiFungibleOrders_asset
        ...AssetOfferModal_asset
      }
    `,
    archetypeDataKey,
  )

  const chain = bestAsk ? bestAsk.item.chain : archetypeData?.chain
  const isTradingEnabled = chain?.isTradingEnabled

  const listingTime = dateFromISO8601(bestAsk?.openedAt || "")
  const renderedScheduledInFutureReason = useScheduledOrderText(
    listingTime,
    bestAsk?.orderType === "ENGLISH" ? "bid on" : "buy",
  )

  const { item, priceType, payment } = bestAsk ?? {}
  const tracker = useMemo(() => {
    return new ItemAnalyticsTracker({
      eventSource: "TradeStation",

      itemId: item?.relayId,
      assetId: item?.relayId,
      collectionSlug: item?.collection?.slug,
      assetContractAddress: item?.assetContract?.address,
      tokenId: item?.tokenId,
      chainId: item?.chain.identifier,

      usdValue: priceType?.usd,
      tokenQuantity: priceType?.unit,
      symbol: payment?.symbol,
    })
  }, [item, priceType, payment])

  const renderBidModal = (onClose: () => unknown) => {
    if (archetypeData) {
      return (
        <AssetOfferModal
          asset={archetypeData}
          onClose={onClose}
          onOrderCreated={onOrdersChanged}
        />
      )
    }

    return <></>
  }

  const getBiddingDisabledReason = (
    serverReason: Maybe<string>,
  ): string | undefined => {
    if (!serverReason) {
      return undefined
    }
    // TODO(i18n): Localize server strings
    switch (serverReason) {
      case "This NFT is in a bad state. Ask the owner to list on OpenSea":
        return t(
          "trade.station.biddingDisabled.offerNotPossible",
          "Offers can’t currently be made on this Solana NFT. This issue is resolved if listed on OpenSea.",
        )
      case "Cannot bid on NFT without owner":
        return t(
          "trade.station.biddingDisabled.noOwner",
          "This SOL NFT doesn’t have an owner, so no offers can be made on it. This often happens when an NFT is burned.",
        )
      default:
        return serverReason
    }
  }

  const renderMakeOfferButton = ({
    onClick,
    assetRelayId,
    isDisabled: isDisabledProp = false,
  }: MakeOfferButtonProps): React.ReactNode => {
    const isCompromised = activeAccount?.isCompromised ?? false
    const disabledReasonCompromised = isCompromised
      ? COMPROMISED_ACCOUNT_ACTION_DISABLED
      : undefined
    const isBiddingDisabled = !archetypeData?.isBiddingEnabled.value

    const biddingDisabledReason = getBiddingDisabledReason(
      archetypeData?.isBiddingEnabled.reason,
    )

    const tooltipDisabledReason =
      disabledReasonCompromised ?? biddingDisabledReason
    const isTooltipDisabled = !tooltipDisabledReason

    return (
      <Tooltip content={tooltipDisabledReason} disabled={isTooltipDisabled}>
        <InlineFlex className="w-full lg:w-1/2">
          <Button
            className="w-full"
            disabled={isDisabledProp || isCompromised || isBiddingDisabled}
            icon="sell"
            variant="secondary"
            onClick={() => {
              if (assetRelayId) {
                trackClickMakeOffer({
                  assetId: assetRelayId,
                  source: "item page",
                })
              }
              onClick()
            }}
          >
            {t("trade.station.makeOffer", "Make offer")}
          </Button>
        </InlineFlex>
      </Tooltip>
    )
  }

  const renderForUnlistedAsset = () => {
    const asset = archetypeData
    if (!asset) {
      return null
    }
    if (!asset.isListable || !isTradingEnabled) {
      return null
    }

    const ownership = asset.largestOwner
    const ownsThisItem = ownership?.owner
      ? isActiveAccount(ownership.owner)
      : false

    if (ownsThisItem && !acceptHighestOffer.bestBid?.relayId) {
      return null
    }

    if (ownsThisItem && asset.acceptOfferDisabled) {
      return null
    }

    return (
      <DivContainer>
        <div className="TradeStation--main" data-testid="trade-station">
          {bestBid ? (
            <>
              <Text className="text-secondary" size="small">
                {t("trade.station.bestOffer", "Best offer")}
              </Text>
              <div className="TradeStation--price-container">
                <>
                  <OrderPrice
                    className="TradeStation--price"
                    order={bestBid}
                    variant="perUnit"
                  />
                  <Block as="span" marginLeft="8px" marginTop="15px">
                    <OrderUsdPrice
                      className="TradeStation--fiat-price"
                      order={bestBid}
                      secondary
                      variant="perUnit"
                    />
                  </Block>
                </>
              </div>
            </>
          ) : null}
          <Flex className="gap-3">
            {ownsThisItem && !asset.acceptOfferDisabled ? (
              <AcceptHighestOfferButton
                className="w-full xl:w-1/2"
                criteriaAsset={archetypeData}
                source="item page"
                tradeSummary={acceptHighestOffer}
              />
            ) : (
              <NetworkUnsupportedGate chainIdentifier={asset.chain.identifier}>
                {({ handleIfNotSupported }) => (
                  <TradeMultiModal
                    focusFirstFocusableElement={false}
                    renderMainModal={renderBidModal}
                    trigger={open =>
                      renderMakeOfferButton({
                        onClick: handleIfNotSupported(open),
                        assetRelayId: asset.relayId,
                      })
                    }
                  />
                )}
              </NetworkUnsupportedGate>
            )}
          </Flex>
        </div>
      </DivContainer>
    )
  }

  const isSeaportEnabled = useIsSeaportEnabled(bestAsk?.item.chain.identifier)

  if (!bestAsk) {
    return renderForUnlistedAsset()
  }

  // HACK: There's an issue where for some reason we don't understand yet `bestAsk.maker` could be undefined
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!bestAsk.maker) {
    return null
  }

  const englishAuctionReservePrice =
    bestAsk.englishAuctionReservePriceType?.unit

  const { orderType } = bestAsk
  const expiration = bestAsk.closedAt
    ? dateFromISO8601(bestAsk.closedAt)
    : undefined
  const hoursLeft = expiration
    ? differenceInHours(expiration, new Date())
    : undefined
  const expiredWithBids =
    hoursLeft !== undefined &&
    hoursLeft < EXPIRED_THRESHOLD_IN_HOURS &&
    orderType === "ENGLISH" &&
    !!bestBid

  const endDate = expiration
  const symbol = bestAsk.payment.symbol
  const displaySymbol = symbol === "WETH" ? "ETH" : symbol

  // If best bid does not match the english auction currency the reserve price does not apply
  const bidMatchesAuctionCurrency =
    bestBid?.payment.relayId === bestAsk.payment.relayId

  const getEnglishAuctionReservePriceMessage = () => {
    if (!englishAuctionReservePrice) {
      return ""
    }
    const englishAuctionReservePriceMet =
      bidMatchesAuctionCurrency &&
      bn(bestBid.priceType.unit).isGreaterThanOrEqualTo(
        englishAuctionReservePrice,
      )

    const ownerPart = isActiveAccount(bestAsk.maker)
      ? t(
          "trade.station.ownerPart",
          "of {{price}} {{symbol}}",
          {
            price: display(englishAuctionReservePrice, symbol),
            symbol: displaySymbol,
          },
          { forceString: true },
        )
      : ""

    return englishAuctionReservePriceMet
      ? t("trade.station.reserveMet", " -- Reserve price {{ownerPart}} met!", {
          ownerPart,
        })
      : t(
          "trade.station.reserveNotMet",
          " -- Reserve price {{ownerPart}} not met.",
          {
            ownerPart,
          },
        )
  }
  const reserveMessage = getEnglishAuctionReservePriceMessage()

  const isScheduledOrderInFuture = isAfter(listingTime, new Date())
  const showTopBid =
    bidMatchesAuctionCurrency &&
    bn(bestBid.priceType.unit).isGreaterThan(bestAsk.priceType.unit)

  const buyModalOnClose = () => {
    trackClosePurchaseFlowMultiModal(bestAsk.item)
  }

  const buyModalOnPrevious = () => {
    trackReturnToPreviousStepPurchaseFlowMultiModal(bestAsk.item)
  }

  const daysBeforeAuctionEnd = endDate
    ? differenceInDays(endDate, new Date())
    : undefined

  const lessThanThreeDaysRemaining =
    daysBeforeAuctionEnd !== undefined ? daysBeforeAuctionEnd < 3 : false

  const ownsThisItem = isActiveAccount(bestAsk.maker)

  const isCompromised = activeAccount?.isCompromised ?? false
  const isDisabled = ownsThisItem || isScheduledOrderInFuture || isCompromised

  const assetChain = bestAsk.item.chain.identifier

  // Hidden for English auctions: making offers is the same as placing bids.
  const isMakeOfferButtonShown =
    isTradingEnabled &&
    orderType !== "ENGLISH" &&
    (!ownsThisItem || archetypeData?.isCurrentlyFungible)

  const accountCompromisedReason = isCompromised
    ? COMPROMISED_ACCOUNT_ACTION_DISABLED
    : undefined
  const ownsThisItemReason = ownsThisItem
    ? t("trade.station.youOwn", "You own this item.")
    : undefined

  const scheduledInFutureReason = isScheduledOrderInFuture
    ? renderedScheduledInFutureReason
    : undefined
  const disabledReason =
    accountCompromisedReason ?? ownsThisItemReason ?? scheduledInFutureReason

  const renderQuickBuyModal = (onClose: () => unknown) =>
    activeAccount ? (
      <QuickBuyModalContent orderId={bestAsk.relayId} onClose={onClose} />
    ) : null

  const renderBuyOrBidRelatedButton = (onClick?: () => unknown) => {
    if (ownsThisItem) {
      return <></>
    }

    if (orderType === "ENGLISH") {
      return (
        <InlineFlex className="w-full lg:w-1/2">
          <Button
            className="w-full"
            disabled={isDisabled}
            icon="account_balance_wallet"
            onClick={() => {
              if (onClick) {
                onClick()
              }
            }}
          >
            {t("trade.station.placeBid", "Place bid")}
          </Button>
        </InlineFlex>
      )
    }

    return isSeaportEnabled ? (
      <AddToCartAndQuickBuyButton
        className="w-full max-w-full lg:max-w-[50%] xl:w-1/2"
        order={bestAsk}
      />
    ) : (
      <QuickBuyButton
        maxWidth={["100%", "100%", "100%", "50%"]}
        order={bestAsk}
        width={{
          _: "100%",
          xl: "50%",
        }}
      />
    )
  }

  const renderBestAsk = () => {
    const content = (
      <>
        <OrderPrice
          className="TradeStation--price"
          order={bestAsk}
          variant="perUnit"
        />
        <Block as="span" marginLeft="8px" marginTop="15px">
          <OrderUsdPrice
            className="TradeStation--fiat-price"
            order={bestAsk}
            secondary
            variant="perUnit"
          />
        </Block>
      </>
    )

    // This can only suspend on client, and Suspense is not yet supported on server
    return IS_SERVER ? (
      content
    ) : (
      <Suspense fallback={<Spinner />}>{content}</Suspense>
    )
  }

  const main = (
    <Form
      className="TradeStation--main"
      data-testid="trade-station"
      onSubmit={e => {
        e.preventDefault()
      }}
    >
      <SpaceBetween>
        <Block>
          <Text className="text-secondary" size="small">
            {orderType !== "ENGLISH"
              ? t("trade.station.currentPrice", "Current price")
              : showTopBid
              ? t("trade.station.topBid", "Top bid")
              : t("trade.station.minimumBid", "Minimum bid")}
            {orderType !== "ENGLISH" && (
              <OrderPriceChanged bestAsk={bestAsk} className="px-2" />
            )}
            {reserveMessage}
          </Text>
          <div className="TradeStation--price-container">
            {orderType === "ENGLISH" && bestBid && showTopBid ? (
              <>
                <OrderPrice
                  className="TradeStation--price"
                  order={bestBid}
                  variant="perUnit"
                />
                <Block as="span" marginLeft="8px" marginTop="15px">
                  <OrderUsdPrice
                    className="TradeStation--fiat-price"
                    order={bestBid}
                    secondary
                    variant="perUnit"
                  />
                </Block>
              </>
            ) : (
              renderBestAsk()
            )}

            {orderType === "ENGLISH" ? (
              <VerticalAligned>
                <Tooltip
                  content={t(
                    "trade.station.highBidderWins",
                    "The highest bidder will win the item at the end of the auction.",
                  )}
                >
                  <Icon
                    aria-label={t(
                      "trade.station.priceAction.priceIncreasing",
                      "Price increasing",
                    )}
                    className={selectClassNames("TradeStation", {
                      "price-auction-icon": true,
                      "price-auction-icon-rising": true,
                      "cursor-pointer": true,
                    })}
                    size={20}
                    value="transit_enterexit"
                  />
                </Tooltip>
              </VerticalAligned>
            ) : null}
          </div>
        </Block>
      </SpaceBetween>

      <Block
        className="gap-3"
        display="flex"
        flexDirection={{ _: "column", lg: "row" }}
        maxWidth="100%"
      >
        <NetworkUnsupportedGate chainIdentifier={assetChain}>
          {({ handleIfNotSupported }) => (
            <>
              <Tooltip
                content={disabledReason}
                disabled={!disabledReason}
                placement="right"
              >
                <TradeMultiModal
                  focusFirstFocusableElement={false}
                  renderMainModal={
                    orderType === "ENGLISH"
                      ? renderBidModal
                      : renderQuickBuyModal
                  }
                  trigger={renderBuyOrBidRelatedButton}
                  onClose={buyModalOnClose}
                  onPrevious={buyModalOnPrevious}
                />
              </Tooltip>

              {isMakeOfferButtonShown ? (
                <TradeMultiModal
                  focusFirstFocusableElement={false}
                  renderMainModal={renderBidModal}
                  trigger={open =>
                    renderMakeOfferButton({
                      onClick: handleIfNotSupported(open),
                      assetRelayId: bestAsk.item.relayId,
                    })
                  }
                  onClose={buyModalOnClose}
                  onPrevious={buyModalOnPrevious}
                />
              ) : null}
            </>
          )}
        </NetworkUnsupportedGate>

        {!isMakeOfferButtonShown &&
          !archetypeData?.isCurrentlyFungible &&
          !archetypeData?.acceptOfferDisabled && (
            <AcceptHighestOfferButton
              className="w-full xl:w-1/2"
              criteriaAsset={archetypeData ?? undefined}
              source="item page"
              tradeSummary={acceptHighestOffer}
            />
          )}
      </Block>

      {isMakeOfferButtonShown &&
        !archetypeData?.isCurrentlyFungible &&
        !archetypeData?.acceptOfferDisabled && (
          <AcceptHighestOfferButton
            className="mt-3 w-full"
            criteriaAsset={archetypeData ?? undefined}
            source="item page"
            tradeSummary={acceptHighestOffer}
          />
        )}

      {data.bestAsk && (
        <ListingFeesSupportsCreator marginTop="16px" orders={[data.bestAsk]} />
      )}
    </Form>
  )
  if (!expiration) {
    return <DivContainer>{main}</DivContainer>
  }

  return (
    <SourceTrackingContextProvider source="TradeStation">
      <ItemAnalyticsContext.Provider value={{ tracker }}>
        <DivContainer>
          <TimerContainer className="TradeStation--header">
            <SpaceBetween className="items-center">
              <Flex>
                {!lessThanThreeDaysRemaining ? (
                  <Flex className="mr-2.5">
                    <Icon
                      className="TradeStation--header-icon"
                      value={expiredWithBids ? "gavel" : "schedule"}
                    />
                  </Flex>
                ) : null}
                {expiredWithBids ? (
                  <Block>
                    {t(
                      "trade.station.auction.sold",
                      "Sold! Matching orders on the blockchain.",
                    )}
                  </Block>
                ) : (
                  <Expiration
                    endDate={endDate}
                    includeCountDown={false}
                    includeDate
                    postfix={undefined}
                    prefix={t("trade.station.auction.saleEnds", "Sale ends")}
                  />
                )}
              </Flex>
              {orderType === "ENGLISH" ? (
                <Tooltip
                  content={
                    <Block>
                      {t(
                        "trade.station.auction.extending",
                        "Extending Auction{{break}}A new highest bid placed under 10 minutes remaining will extend the auction by an additional 10 minutes.",
                        { break: <br /> },
                      )}
                    </Block>
                  }
                >
                  <Icon
                    className="TradeStation--header-icon-help cursor-pointer"
                    value="help"
                  />
                </Tooltip>
              ) : null}
            </SpaceBetween>
            {lessThanThreeDaysRemaining ? (
              <Block marginTop="8px">
                <Timer
                  endDate={format(
                    endDate || new Date(),
                    SERIALIZED_DATE_FORMAT,
                  )}
                />
              </Block>
            ) : null}
          </TimerContainer>
          {main}
        </DivContainer>
      </ItemAnalyticsContext.Provider>
    </SourceTrackingContextProvider>
  )
}

const TimerContainer = styled(Block)`
  padding: 10px;
  border-bottom: 1px solid
    ${props => props.theme.colors.components.border.level2};
  font-weight: initial;
  padding: 20px;

  .TradeStation--header-icon-help {
    &:hover {
      color: ${props => props.theme.colors.text.primary};
    }
  }
`

const DivContainer = styled.div`
  border-radius: ${props => props.theme.borderRadius.default};
  border: 1px solid ${props => props.theme.colors.components.border.level2};
  background: ${props =>
    props.theme.type === "light"
      ? props.theme.colors.base2
      : props.theme.colors.components.background.gray1};

  .TradeStation--main {
    padding: 20px;

    .TradeStation--ask-label {
      color: ${props => props.theme.colors.text.secondary};
    }

    .TradeStation--price-container {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 8px;

      .TradeStation--quantity-badge {
        margin: auto 8px auto 0;
      }

      .TradeStation--price {
        font-size: 30px;
      }

      .TradeStation--fiat-price {
        font-size: 15px;
      }

      .TradeStation--price-auction-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 22px;
        color: ${props => props.theme.colors.white};
        height: 24px;
        margin-left: 4px;
        width: 24px;

        &.TradeStation--price-auction-icon-rising {
          transform: rotate(180deg);
        }
      }
    }
  }

  .TradeStation--modal {
    display: inline-block;
  }
`
