import { differenceInMinutes } from "date-fns"
import {
  ENGLISH_AUCTION_POLLING_TIMEFRAME_IN_MINUTES,
  POLLING_INTERVAL,
} from "../../constants"
import { clearCache } from "../graphql/environment/middlewares/cacheMiddleware"
import { dateFromISO8601 } from "./datetime"
import { poll } from "./promise"

export const pollEnglishAuction = (
  closedAt: string,
  refetch: () => void,
): void => {
  poll({
    delay: POLLING_INTERVAL,
    fn: () => {
      const closedAtFromISO = dateFromISO8601(closedAt)
      const minutesUntilClose = differenceInMinutes(closedAtFromISO, new Date())
      if (
        minutesUntilClose > -1 &&
        minutesUntilClose < ENGLISH_AUCTION_POLLING_TIMEFRAME_IN_MINUTES
      ) {
        clearCache()
        refetch()
      }
    },
  })
}
