import React from "react"
import { Flex } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled, { css } from "styled-components"
import { CreateListingButton } from "@/components/orders/CreateListingButton"
import { Z_INDEX } from "@/constants/zIndex"
import { useActiveAccount } from "@/containers/WalletProvider/WalletProvider.react"
import { Button } from "@/design-system/Button"
import { Tooltip } from "@/design-system/Tooltip"
import { COMPROMISED_ACCOUNT_ACTION_DISABLED } from "@/features/settings/constants/messages"
import { useIsSSFEnabled } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import { OrderManager_item$key } from "@/lib/graphql/__generated__/OrderManager_item.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { getAssetEditUrl } from "@/lib/helpers/asset"
import { bn } from "@/lib/helpers/numberUtils"
import { media } from "@/styles/styleUtils"
import { EditListingButton } from "./EditListingButton.react"

type Props = {
  className?: string
  item: OrderManager_item$key
  onOrdersChanged: () => unknown
  onReviewListings?: () => unknown
}

const BUTTON_WIDTH_PX = "166px"

export const OrderManager = ({
  item: itemKey,
  onOrdersChanged,
  className,
  onReviewListings,
}: Props) => {
  const t = useTranslate("components")
  const item = useFragment(
    graphql`
      fragment OrderManager_item on ItemType {
        __typename
        chain {
          isTradingEnabled
        }
        tradeSummary {
          bestAsk {
            __typename
            ...EditListingButton_listing
          }
        }

        ... on AssetType {
          isEditable {
            value
          }
          ownedQuantity(identity: {})
          isCurrentlyFungible
          ...asset_edit_url
        }

        ...CreateListingButton_item
        ...EditListingButton_item
      }
    `,
    itemKey,
  )

  const { __typename, tradeSummary } = item
  const isAsset = __typename === "AssetType"
  const isFungible = isAsset && item.isCurrentlyFungible
  const activeAccount = useActiveAccount()
  const isSSFEnabled = useIsSSFEnabled()
  const isCompromised = activeAccount?.isCompromised ?? false
  const hasOpenListing = !!tradeSummary.bestAsk?.__typename
  const isTradingEnabled = item.chain.isTradingEnabled

  const ownedQuantity = isAsset ? bn(item.ownedQuantity ?? 0) : bn(0)
  const ownsEnoughToSell = ownedQuantity.gt(0)

  const showSellButton =
    isTradingEnabled && !isFungible && !hasOpenListing && ownsEnoughToSell
  const showEditAssetButton = isAsset && item.isEditable?.value
  const showEditListingButton = !isFungible && hasOpenListing

  const hasButtons =
    showSellButton || showEditAssetButton || showEditListingButton
  if (!hasButtons) {
    return null
  }

  return (
    <DivContainer className={className}>
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <div className="OrderManager--cta-container">
        <Flex className="gap-2.5">
          {isSSFEnabled && showEditAssetButton && (
            <Button
              href={getAssetEditUrl(item)}
              style={{ width: BUTTON_WIDTH_PX }}
              variant="secondary"
            >
              {t("orderManager.editItem.button", "Edit item")}
            </Button>
          )}
          {showEditListingButton && (
            <EditListingButton
              item={item}
              listing={tradeSummary.bestAsk}
              source="item page"
              style={{ width: BUTTON_WIDTH_PX }}
              onOrdersChanged={onOrdersChanged}
              onReviewListings={onReviewListings}
            />
          )}
          {showSellButton && (
            <Tooltip
              content={COMPROMISED_ACCOUNT_ACTION_DISABLED}
              disabled={!isCompromised}
            >
              <span>
                <CreateListingButton
                  disabled={isCompromised}
                  item={item}
                  openFullPage
                  source="item page"
                  style={{ width: BUTTON_WIDTH_PX }}
                />
              </span>
            </Tooltip>
          )}
        </Flex>
      </div>
    </DivContainer>
  )
}

const DivContainer = styled.div`
  bottom: 0px;
  position: fixed;
  top: auto;
  width: 100%;
  right: 0px;
  padding: 12px 0px;
  z-index: ${Z_INDEX.MANAGER_BAR};
  background-color: ${props => props.theme.colors.base1};
  border-top: 1px solid ${props => props.theme.colors.components.border.level2};
  display: flex;
  overflow-x: auto;

  .OrderManager--cta-container {
    align-items: center;
    display: flex;
    flex: 1 0;
    justify-content: flex-end;
    padding: 0 20px;
  }

  .Modal {
    .OrderManager--cta-container {
      margin: 10px 0px 20px;
      justify-content: center;
      .OrderManager--cta {
        padding: 18px;
      }
    }
    .OrderManager--modal-error-text {
      color: ${props => props.theme.colors.error};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .OrderManager--loader {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }

  ${media({
    lg: css`
      position: sticky;
      top: 72px;
      border-top: none;
      border-bottom: 1px solid
        ${props => props.theme.colors.components.border.level2};

      .OrderManager--cta-container {
        margin: auto;
        padding: 0;
      }
    `,
  })}
`
