import React from "react"
import { Text } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { Block } from "@/design-system/Block"
import { Flex, FlexProps } from "@/design-system/Flex"
import { readOrderFees } from "@/features/orders/components/AcceptOfferModalContent/readOrderFees"
import { useTranslate } from "@/hooks/useTranslate"
import { ListingFeesSupportsCreator_orders$key } from "@/lib/graphql/__generated__/ListingFeesSupportsCreator_orders.graphql"
import { CreatorFeeSupportIcon } from "../CreatorFeeSupportIcon.react"

type Props = {
  orders: ListingFeesSupportsCreator_orders$key
} & Omit<FlexProps, "order">

export const ListingFeesSupportsCreator = ({
  orders: ordersKey,
  ...rest
}: Props) => {
  const t = useTranslate("common")
  const orders = useFragment(
    graphql`
      fragment ListingFeesSupportsCreator_orders on OrderV2Type
      @relay(plural: true) {
        side
        item {
          ... on AssetType {
            totalCreatorFee
          }
        }
        ...readOrderFees_order
      }
    `,
    ordersKey,
  )

  const listings = orders.filter(order => order.side === "ASK")

  const numListingsWithFullFees = listings.filter(listing => {
    const { enforcedBasisPoints } = readOrderFees(listing)

    return (
      listing.item.totalCreatorFee &&
      listing.item.totalCreatorFee === enforcedBasisPoints
    )
  }).length

  if (numListingsWithFullFees === 0) {
    return null
  }

  return (
    <Flex {...rest}>
      <Block marginRight="10px">
        <CreatorFeeSupportIcon />
      </Block>

      <Text.Body weight="semibold">
        {t("listingFeesSupportsCreator.title", "Supports creator")}{" "}
        <Text.Body className="text-secondary" size="medium">
          {numListingsWithFullFees === 1 &&
          listings.length === numListingsWithFullFees
            ? t(
                "listingFeesSupportsCreator.descriptionSingle",
                "This listing is paying the collection creator their suggested creator earnings.",
              )
            : t(
                "listingFeesSupportsCreator.description",
                "{{count}} of these listings are paying the collection creator their suggested creator earnings.",
                {
                  count: numListingsWithFullFees,
                },
              )}
        </Text.Body>
      </Text.Body>
    </Flex>
  )
}
