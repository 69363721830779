/**
 * @generated SignedSource<<4340a9b493826346c38c0dd5d9c0d3d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderPriceChanged_bestAsk$data = {
  readonly item: {
    readonly relayId: string;
  };
  readonly perUnitPriceType: {
    readonly usd: string;
  };
  readonly " $fragmentType": "OrderPriceChanged_bestAsk";
};
export type OrderPriceChanged_bestAsk$key = {
  readonly " $data"?: OrderPriceChanged_bestAsk$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderPriceChanged_bestAsk">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderPriceChanged_bestAsk",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "relayId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceType",
      "kind": "LinkedField",
      "name": "perUnitPriceType",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "usd",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};

(node as any).hash = "737bbf11b0a8c376afcaa9f31ea4b9dc";

export default node;
