/**
 * @generated SignedSource<<3bf0c547a29ac5f96d474ca8b5fdf99c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderV2Side = "ASK" | "BID" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ListingFeesSupportsCreator_orders$data = ReadonlyArray<{
  readonly item: {
    readonly totalCreatorFee?: number | null;
  };
  readonly side: OrderV2Side | null;
  readonly " $fragmentSpreads": FragmentRefs<"readOrderFees_order">;
  readonly " $fragmentType": "ListingFeesSupportsCreator_orders";
}>;
export type ListingFeesSupportsCreator_orders$key = ReadonlyArray<{
  readonly " $data"?: ListingFeesSupportsCreator_orders$data;
  readonly " $fragmentSpreads": FragmentRefs<"ListingFeesSupportsCreator_orders">;
}>;

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "FeeTypeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FeeType",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "basisPoints",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOpenseaFee",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ListingFeesSupportsCreator_orders",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "side",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCreatorFee",
              "storageKey": null
            }
          ],
          "type": "AssetType",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "readOrderFees_order",
      "selections": [
        {
          "alias": null,
          "args": (v0/*: any*/),
          "concreteType": "FeeTypeConnection",
          "kind": "LinkedField",
          "name": "makerFees",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "makerFees(first:10)"
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "concreteType": "FeeTypeConnection",
          "kind": "LinkedField",
          "name": "takerFees",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": "takerFees(first:10)"
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};
})();

(node as any).hash = "a6cf83406e8675c8fd66fb0140e466a5";

export default node;
