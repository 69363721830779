import React from "react"
import { Text } from "@opensea/ui-kit"
import {
  differenceInDays,
  startOfDay,
  startOfToday,
  startOfTomorrow,
} from "date-fns"
import { Block } from "@/design-system/Block"
import { useTranslate } from "@/hooks/useTranslate"
import { useI18nDateAtTime } from "@/lib/helpers/datetime"
import { Countdown } from "./Countdown.react"

type Props = {
  prefix?: React.ReactNode
  postfix?: React.ReactNode
  includeDate?: boolean
  endDate?: Date
  includeCountDown?: boolean
}

export const Expiration = ({
  prefix = "",
  postfix = "",
  includeDate = true,
  endDate,
  includeCountDown = true,
}: Props) => {
  const t = useTranslate("components")
  const endDateLocal = endDate || new Date()
  const startOfEndingDay = endDate ? startOfDay(endDateLocal) : undefined
  const endDateText = useI18nDateAtTime(endDateLocal)

  const date = endDate && includeDate ? endDateText : ""
  const dateText = <Text>{includeCountDown ? `(${date})` : date}</Text>

  const today = startOfToday()

  const countDownText =
    endDate && startOfEndingDay && includeCountDown ? (
      startOfEndingDay.valueOf() <= startOfTomorrow().valueOf() ? (
        <>
          <Text color="inherit" weight="semibold">
            {startOfEndingDay.valueOf() === today.valueOf()
              ? t("expiration.today", "today")
              : t("expiration.tomorrow", "tomorrow")}
          </Text>
          &nbsp;{t("expiration.in", "in")}&nbsp;
          <Text color="inherit" weight="semibold">
            <Countdown date={endDateLocal} />
          </Text>
        </>
      ) : (
        <>
          &nbsp;{t("expiration.in", "in")}&nbsp;
          <Text color="inherit" weight="semibold">
            {differenceInDays(startOfEndingDay, today)}{" "}
            {t("expiration.days", "days")}
          </Text>
        </>
      )
    ) : null

  const insertSpace = <>&nbsp;</>

  return (
    <Block display="inline">
      <Text>{prefix}</Text>
      {prefix ? insertSpace : null}
      {countDownText}
      {countDownText ? insertSpace : null}
      {dateText}
      {insertSpace}
      <Text>{postfix}</Text>
    </Block>
  )
}
