/* eslint-disable tailwindcss/no-custom-classname */
import React from "react"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { AccountLink } from "@/components/accounts/AccountLink.react"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { useTranslate } from "@/hooks/useTranslate"
import { PrivateListingBanner_data$key } from "@/lib/graphql/__generated__/PrivateListingBanner_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import NoticeBanner from "./NoticeBanner.react"

type PrivateListingBannerProps = {
  dataKey: PrivateListingBanner_data$key
}

export const PrivateListingBanner = ({
  dataKey,
}: PrivateListingBannerProps) => {
  const data = useFragment(
    graphql`
      fragment PrivateListingBanner_data on OrderV2Type {
        taker {
          address
          ...AccountLink_data
          ...wallet_accountKey
        }
        maker {
          ...wallet_accountKey
        }
      }
    `,
    dataKey,
  )

  const { maker, taker } = data
  const t = useTranslate("components")
  const { isActiveAccount } = useWallet()
  const isOwner = isActiveAccount(maker)
  const isPrivateListingRecipient = isActiveAccount(taker)
  const showBanner = isOwner || isPrivateListingRecipient
  if (!taker || !showBanner) {
    return null
  }

  const identityPart = (
    <Block display="inline-block">
      <AccountLink
        className="PrivateListingBanner--account-link"
        dataKey={taker}
        mode="light"
      />
    </Block>
  )

  return (
    <DivContainer>
      <NoticeBanner>
        <div className="PrivateListingBanner--content">
          {isOwner ? (
            <div className="PrivateListingBanner--identity-sentence">
              {t(
                "privateListingBanner.forOther",
                "This is a private listing that you made for {{identityPart}}.",
                { identityPart },
              )}
            </div>
          ) : isPrivateListingRecipient ? (
            t(
              "privateListingBanner.forYou",
              "This listing is reserved for you!",
            )
          ) : null}
        </div>
      </NoticeBanner>
    </DivContainer>
  )
}

const DivContainer = styled.div`
  width: 100%;

  .PrivateListingBanner--content {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    justify-content: center;
    width: 100%;

    .PrivateListingBanner--identity-sentence {
      margin-right: 4px;

      .PrivateListingBanner--account-link {
        margin-left: 4px;
        padding: 6px 0;
        vertical-align: bottom;
      }
    }
  }
`
