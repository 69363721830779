/**
 * @generated SignedSource<<7adab14d388a307642a53f5439eae9db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TradeStation_acceptHighestOffer$data = {
  readonly bestBid: {
    readonly relayId: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"AcceptHighestOfferButton_tradeSummary">;
  readonly " $fragmentType": "TradeStation_acceptHighestOffer";
};
export type TradeStation_acceptHighestOffer$key = {
  readonly " $data"?: TradeStation_acceptHighestOffer$data;
  readonly " $fragmentSpreads": FragmentRefs<"TradeStation_acceptHighestOffer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TradeStation_acceptHighestOffer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderV2Type",
      "kind": "LinkedField",
      "name": "bestBid",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "relayId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AcceptHighestOfferButton_tradeSummary"
    }
  ],
  "type": "TradeSummaryType",
  "abstractKey": null
};

(node as any).hash = "87170eb37a3c8ff430c0a26c01adee20";

export default node;
