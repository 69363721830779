import React from "react"
import { intervalToDuration } from "date-fns"
import { useInterval, useUpdate } from "react-use"
import { formatDurationAsClock } from "@/lib/helpers/datetime"

type CountdownProps = {
  date: Date
}

export const Countdown = ({ date }: CountdownProps) => {
  const update = useUpdate()
  useInterval(update, 1000)

  let maybeDuration: Duration
  try {
    maybeDuration = intervalToDuration({ start: new Date(), end: date })
  } catch {
    maybeDuration = {}
  }

  const duration = {
    days: maybeDuration.days || 0,
    hours: maybeDuration.hours || 0,
    minutes: maybeDuration.minutes || 0,
    seconds: maybeDuration.seconds || 0,
  }

  return <>{formatDurationAsClock(duration)}</>
}
