/**
 * @generated SignedSource<<f8edd1dfbb236fd981fe73d5935f0554>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PrivateListingBanner_data$data = {
  readonly maker: {
    readonly " $fragmentSpreads": FragmentRefs<"wallet_accountKey">;
  };
  readonly taker: {
    readonly address: string;
    readonly " $fragmentSpreads": FragmentRefs<"AccountLink_data" | "wallet_accountKey">;
  } | null;
  readonly " $fragmentType": "PrivateListingBanner_data";
};
export type PrivateListingBanner_data$key = {
  readonly " $data"?: PrivateListingBanner_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"PrivateListingBanner_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v1 = {
  "kind": "InlineDataFragmentSpread",
  "name": "wallet_accountKey",
  "selections": [
    (v0/*: any*/)
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PrivateListingBanner_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "taker",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AccountLink_data"
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountType",
      "kind": "LinkedField",
      "name": "maker",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "OrderV2Type",
  "abstractKey": null
};
})();

(node as any).hash = "11b3bdeaa6e7f63bf872e5fe662211d8";

export default node;
