/**
 * @generated SignedSource<<51fdf708fcfcb19ba6ed618358d2ce7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderManager_item$data = {
  readonly __typename: string;
  readonly chain: {
    readonly isTradingEnabled: boolean;
  };
  readonly isCurrentlyFungible?: boolean;
  readonly isEditable?: {
    readonly value: boolean;
  };
  readonly ownedQuantity?: string | null;
  readonly tradeSummary: {
    readonly bestAsk: {
      readonly __typename: "OrderV2Type";
      readonly " $fragmentSpreads": FragmentRefs<"EditListingButton_listing">;
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CreateListingButton_item" | "EditListingButton_item" | "asset_edit_url">;
  readonly " $fragmentType": "OrderManager_item";
};
export type OrderManager_item$key = {
  readonly " $data"?: OrderManager_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderManager_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderManager_item",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ChainType",
      "kind": "LinkedField",
      "name": "chain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isTradingEnabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TradeSummaryType",
      "kind": "LinkedField",
      "name": "tradeSummary",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestAsk",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EditListingButton_listing"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AbleToType",
          "kind": "LinkedField",
          "name": "isEditable",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "identity",
              "value": {}
            }
          ],
          "kind": "ScalarField",
          "name": "ownedQuantity",
          "storageKey": "ownedQuantity(identity:{})"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCurrentlyFungible",
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "asset_edit_url",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetContractType",
              "kind": "LinkedField",
              "name": "assetContract",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "chain",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tokenId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CollectionType",
              "kind": "LinkedField",
              "name": "collection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "type": "AssetType",
      "abstractKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateListingButton_item"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditListingButton_item"
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "6f1c074ed8066cdc34dbf14cc6400d5f";

export default node;
