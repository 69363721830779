/* eslint-disable tailwindcss/no-custom-classname */
import React, { useState } from "react"
import { Flex, Text, classNames, Skeleton } from "@opensea/ui-kit"
import { intervalToDuration, add, isBefore, differenceInDays } from "date-fns"
import { useInterval } from "react-use"
import styled, { css } from "styled-components"
import { Block } from "@/design-system/Block"
import { useTranslate } from "@/hooks/useTranslate"
import { padFrontZeroes } from "@/lib/helpers/numberUtils"
import { media } from "@/styles/styleUtils"

type Props = {
  endDate: string | undefined
  className?: string
}

const oneSecond = 1000
export const Timer = ({ endDate, className }: Props) => {
  const t = useTranslate("components")
  const end = endDate ? new Date(endDate) : new Date()
  const [start, setStart] = useState(new Date())
  const [duration, setDuration] = useState(intervalToDuration({ start, end }))

  useInterval(() => {
    if (isBefore(start, end)) {
      const newStart = add(start, { seconds: 1 })
      setStart(newStart)
      setDuration(intervalToDuration({ start: newStart, end }))
    }
  }, oneSecond)

  const days = differenceInDays(end, start)
  const hours = duration.hours ?? 0
  const minutes = duration.minutes ?? 0
  const seconds = duration.seconds ?? 0

  const lessThanOneHourRemains = days <= 0 && hours <= 0

  if (!endDate) {
    return (
      <StyledContainer lessThanOneHourRemains={lessThanOneHourRemains}>
        <Flex className={className}>
          <Block className="Timer--time-unit">
            <Skeleton.Block className="h-6" />
            <Text size="small">{t("timer.days", "Days")}</Text>
          </Block>
          <Block className="Timer--time-unit">
            <Skeleton.Block className="h-6" />
            <Text size="small">{t("timer.hours", "Hours")}</Text>
          </Block>
          <Block className="Timer--time-unit">
            <Skeleton.Block className="h-6" />
            <Text size="small">{t("timer.minutes", "Minutes")}</Text>
          </Block>
          <Block className="Timer--time-unit">
            <Skeleton.Block className="h-6" />
            <Text size="small">{t("timer.seconds", "Seconds")}</Text>
          </Block>
        </Flex>
      </StyledContainer>
    )
  }

  return (
    <StyledContainer lessThanOneHourRemains={lessThanOneHourRemains}>
      <Flex className={classNames("flex-wrap", className)}>
        {days > 0 ? (
          <Block className="Timer--time-unit">
            <Block className="Timer--numbers">{padFrontZeroes(days, 2)}</Block>
            <Text size="small">{t("timer.days", "Days")}</Text>
          </Block>
        ) : null}
        <Block className="Timer--time-unit">
          <Block className="Timer--numbers">{padFrontZeroes(hours, 2)}</Block>
          <Text size="small">{t("timer.hours", "Hours")}</Text>
        </Block>
        <Block className="Timer--time-unit">
          <Block className="Timer--numbers">{padFrontZeroes(minutes, 2)}</Block>
          <Text size="small">{t("timer.minutes", "Minutes")}</Text>
        </Block>
        <Block className="Timer--time-unit">
          <Block className="Timer--numbers">{padFrontZeroes(seconds, 2)}</Block>
          <Text size="small">{t("timer.seconds", "Seconds")}</Text>
        </Block>
      </Flex>
    </StyledContainer>
  )
}

const StyledContainer = styled(Block)<{ lessThanOneHourRemains: boolean }>`
  font-size: 20px;
  font-weight: 500;

  .Timer--time-unit {
    :not(:last-child) {
      margin-right: 16px;

      ${media({
        xs: css`
          margin-right: 32px;
        `,

        sm: css`
          margin-right: 50px;
        `,
      })}
    }

    .Timer--numbers {
      color: ${props =>
        props.lessThanOneHourRemains
          ? props.theme.colors.error
          : props.theme.colors.text.primary};
    }
  }
`
